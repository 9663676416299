import React from 'react';
import logo from './golden-itwolf.svg';
import './App.css';
import {Button, Stack} from "react-bootstrap";

function App() {
    return (
        <Stack className="App-container">
            <img src={logo} className="App-logo" alt="ITWolf Cloud"/>
            <Button variant="outline-link" href="https://www.itwolf.cz">
                Pokračujte tudy <strong>ITWolf.cz</strong>
            </Button>
        </Stack>
    );
}

export default App;
